import React from "react";
import Navbar from "../Components/Navbar";
import subpagebanner from "../Assets/subpagebanner.png";
import footer from "../Assets/footer.png";
import Card from "../Components/Card.jsx";
import Card1 from "../Components/Card1.jsx";
import Card2 from "../Components/Card2.jsx";
import Card3 from "../Components/Card3.jsx";
import Card5 from "../Components/Card5.jsx";
import Card6 from "../Components/Card6.jsx";
import Card7 from "../Components/Card7.jsx";
import Card8 from "../Components/Card8.jsx";
import Card9 from "../Components/Card9.jsx";

const About = () => {
  return (
    <div className="App">
      <div className="home-container">
        <Navbar />
        <img src={subpagebanner} alt="" />
      </div>

      <div className="community-container">
        <h1 className="secondary-heading">Judges</h1>

        <Card5 />
        <Card6 />
        <Card7 />
        <Card8 />
        <Card9 />

        <h1 className="secondary-heading">Mentors</h1>

        <>
          <Card />
          <Card1 />
          <Card2 />
          <Card3 />
        </>
        <br />
        <br />
        <br />
        <br />

        <h3 class="textbold">
          To learn more about the NUS Business Analytics Centre, please visit: 
          <a href="https://msba.nus.edu.sg/://gurugame.ai/">
            https://msba.nus.edu.sg/{" "}
          </a>
        </h3>

        <br />

        <h3 class="textbold">
          For more information about GURU NETWORK, please visit: 
          <a href="https://gurugame.ai/">https://gurugame.ai/</a> or{" "}
          <a href="https://gurutool.ai/">https://gurutool.ai/</a>
        </h3>

        <br />
        <br />
        <br />
        <br />
        <br />

        <img src={footer} alt="" />
      </div>
    </div>
  );
};

export default About;
